var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fadeIn", mode: "in-out" } }, [
    _c(
      "li",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isLoading,
            expression: "!isLoading",
          },
        ],
        staticClass:
          "mb-4 pb-4 flex flex-wrap flex-col xxl:flex-row border-b border-1 border-solid border-grey-light",
      },
      [
        _c("div", { staticClass: "w-full" }, [
          _c("div", { staticClass: "flex flex-col lg:flex-row -mx-3" }, [
            _c(
              "div",
              { staticClass: "flex flex-row lg:items-top w-full px-3" },
              [
                _c(
                  "div",
                  { staticClass: "flex flex-col h-full w-2/5 mr-5" },
                  [
                    _vm.item.typeId === 4
                      ? [
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex justify-center align-center py-2",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "relative flex flex-col items-center justify-center h-24 w-24 block rounded-full bg-blue-dark overflow-hidden",
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "mb-1 font-sans-bold text-3xl leading-none text-white",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatDate(
                                            _vm.item.startDate,
                                            "date"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "font-sans-bold text-2xs leading-none tracking-wide text-blue-light uppercase",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatDate(
                                            _vm.item.startDate,
                                            "day"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      : [
                          _c(
                            "div",
                            {
                              staticClass:
                                "block relative object-fit-fallback aspect-ratio-box aspect-ratio-box--8-5",
                            },
                            [
                              _c("img", {
                                staticClass:
                                  "absolute lazyload w-full h-full block object-cover",
                                attrs: {
                                  src:
                                    !!_vm.item.image && !!_vm.item.image[0]
                                      ? _vm.item.image[0].url
                                      : "/assets/img/martha_recipe_placeholder.jpg",
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                        ],
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "w-full flex flex-col pr-3" },
                  [
                    _c("a", { attrs: { href: _vm.item.url } }, [
                      _c(
                        "h2",
                        {
                          staticClass:
                            "heading-3 text-grey-dark hover:text-grey",
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.item.title.length > 117
                                ? _vm.item.title.slice(0, 114) + "..."
                                : _vm.item.title
                            )
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.item.typeId === 4
                      ? [
                          _c("div", { staticClass: "flex mt-auto" }, [
                            _c("div", { staticClass: "flex items-center" }, [
                              _c("span", { staticClass: "pr-1 text-blue" }, [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "fill-current block",
                                    attrs: {
                                      width: "12px",
                                      height: "12px",
                                      viewBox: "0 0 768 768",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        d: "M549.373 83.24c44.826 44.826 67.24 99.95 67.24 165.373 0 79.961-42.403 192.632-127.21 338.014-39.98 69.057-75.115 124.18-105.403 165.373-30.288-41.192-65.422-96.316-105.403-165.373-84.807-145.384-127.21-258.054-127.21-338.014 0-65.423 22.413-120.547 67.24-165.373s99.95-67.24 165.373-67.24c65.423 0 120.547 22.413 165.373 67.24zM322.212 310.4c16.961 15.75 37.557 23.624 61.787 23.624s44.524-8.178 60.879-24.533c16.356-16.356 24.533-36.648 24.533-60.879s-8.178-44.524-24.533-60.879c-16.356-16.356-36.648-24.533-60.879-24.533s-44.524 8.178-60.879 24.533c-16.356 16.356-24.533 36.648-24.533 60.879s7.875 44.826 23.624 61.787z",
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "pr-3 small-text text-grey-dark group-hover:text-grey",
                                },
                                [_vm._v(_vm._s(_vm.item.location))]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "flex items-center" }, [
                              _c("span", { staticClass: "pr-1 text-blue" }, [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "stroke-current block",
                                    attrs: {
                                      width: "12px",
                                      height: "12px",
                                      viewBox: "0 0 24 24",
                                      fill: "none",
                                      "stroke-width": "2",
                                      "stroke-linecap": "square",
                                      "stroke-linejoin": "arcs",
                                    },
                                  },
                                  [
                                    _c("circle", {
                                      attrs: { cx: "12", cy: "12", r: "10" },
                                    }),
                                    _vm._v(" "),
                                    _c("polyline", {
                                      attrs: { points: "12 6 12 12 16 14" },
                                    }),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "small-text text-grey-dark group-hover:text-grey",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatDate(_vm.item.startDate, "time")
                                    )
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    !!_vm.item.categories
                      ? _c(
                          "div",
                          { staticClass: "mt-auto" },
                          _vm._l(
                            _vm.item.categories.slice(1, 3),
                            function (cat, key) {
                              return _c(
                                "span",
                                {
                                  key: key,
                                  staticClass:
                                    "small-text text-grey-dark inline-block group-hover:text-grey",
                                },
                                [
                                  _vm._v(_vm._s(cat.title)),
                                  key <
                                  _vm.item.categories.slice(1, 3).length - 1
                                    ? _c(
                                        "span",
                                        { staticClass: "inline-block px-1" },
                                        [_vm._v("|")]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.item.recipePreparationTime
                      ? _c("div", { staticClass: "mt-auto" }, [
                          _c(
                            "span",
                            {
                              staticClass:
                                "small-text text-grey-dark inline-block group-hover:text-grey",
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass: "inline-block mr-1",
                                  attrs: {
                                    width: "12px",
                                    height: "12px",
                                    xmlns: "http://www.w3.org/2000/svg",
                                    viewBox: "0 0 24 24",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 110-16 8 8 0 010 16zm-1-13h2v6h-2zm0 8h2v2h-2z",
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.item.recipePreparationTime) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }